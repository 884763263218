<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      v-bind="configOptions"
      @handleNewClick="handleSubmit"
      :pageInfo="pageInfo"
    >
      <template #customForm v-if="pageType === 'view'"></template>
    </page-form>
  </div>
</template>
<script setup>
import pageForm from '@/components/PageForm/page-form.vue'
import {
  baseNewFormConfig,
  baseUpdateFormConfig,
  baseViewFormConfig
} from './config/index'
import { ref, getCurrentInstance, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import i18n from '@/i18n'
import { findCurrentSelectedTreeNode } from '@/utils/util'
import { cloneDeep } from 'lodash'
import { getParamsOptions } from '@/utils/common'

const routes = useRoute()
const router = useRouter()
const { t } = i18n.global
// const cxt = getCurrentInstance()
// const bus = cxt.appContext.config.globalProperties.$bus
// 1、预定义当前页面的map映射数组
const pageMapArray = [
  {
    // view routesName 跟你配置路由的name相关
    routesName: 'welcomePageView',
    baseFormConfig: baseViewFormConfig,
    pageTitle: t('general.router-welcome-page-view'),
    pageType: 'view'
  },
  {
    // view routesName 跟你配置路由的name相关
    routesName: 'WelcomePageEdit',
    baseFormConfig: baseUpdateFormConfig,
    pageTitle: t('general.router-welcome-page-edit'),
    pageType: 'edit',
    isResetFields: false
  },
  {
    routesName: 'welcomePageNew',
    baseFormConfig: baseNewFormConfig,
    pageTitle: t('general.router-welcome-page-new'),
    pageType: 'add'
  }
]
// 2、根据当前路由对象中的name属性判断当前页面
const currentPageConfig = pageMapArray.find((item) => {
  if (item.routesName === routes.name) return item
})

const store = useStore()

// 3、将需要用到的属性解构出来
const { baseFormConfig, pageType } = currentPageConfig

const baseFormConfigRef = computed(() => {
  return baseFormConfig
})

// 4、区分当前页面是否需要转化options选项
const configOptions = ref({})
const id = routes.params.id

// const downLoad = () => {
//   store.dispatch('ota/getOtaCount', id)
// }

currentPageConfig.baseFormConfig = baseFormConfigRef
configOptions.value = currentPageConfig

const { appContext } = getCurrentInstance()
const pageInfo = ref({})
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit

const tenantList = getParamsOptions('tenantList')
// 5、定义方法获取当前页面的初始化数据
const getCurrentPageInfo = async () => {
  const welcomePageInfo = await store.dispatch('file/getWelcomeDetailByID', {
    id
  })
  if (JSON.stringify(welcomePageInfo) !== '{}') {
    welcomePageInfo.tenantId =
      findCurrentSelectedTreeNode(tenantList, welcomePageInfo.tenantId, 'id') ||
      ''
  }
  welcomePageInfo.tenantId =
    store.getters.tenantDataMap[welcomePageInfo?.tenantId]
  pageInfo.value = welcomePageInfo
}

// 6、判断当前页是否需要调用getCurrentPageInfo
if (['view', 'edit'].includes(pageType)) {
  getCurrentPageInfo()
} else {
  pageInfo.value = {}
}

// 7、定义被提交的方法
const handleSubmit = async (data) => {
  // 转换引用
  const params = cloneDeep(data)
  let url = 'handleAddWelcomePage'

  // 获取当前welcomePage的img值

  params.tenantId = data.tenantId
    ? findCurrentSelectedTreeNode(tenantList, data.tenantId, 'name')
    : ''
  if (pageType === 'edit') {
    params.id = id
    url = 'handleUpdateWelcomePage'
  }
  handleMessageCommit(`file/${url}`, params).then(() => {
    router.push('/file/apphome')
  })
}
</script>

<style scoped lang="scss">
.base-form {
  .form-item {
    padding: 5px 20px;
    .file-wrap {
      display: flex;
      align-items: center;
      &:hover {
        cursor: pointer;
        color: #64b5fc;
      }
      .download-icon {
        font-size: 16px;
        margin-left: 5px;
      }
    }
  }
}
</style>
