export const baseViewFormConfig = {
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'file.picture-name',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'tenantId',
      type: 'input',
      label: 'user.tenant',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'pictureUrl',
      type: 'imgUpload',
      label: 'file.welcome-picture',
      otherOptions: {
        disabled: true
      }
    }
  ]
}
