import { basicRequiredRule } from '@/template/rule'

export const baseUpdateFormConfig = {
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'file.picture-name'
    },
    {
      field: 'tenantId',
      type: 'input',
      label: 'user.tenant',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      otherOptions: {
        maxlength: 200
      }
    },
    {
      field: 'pictureUrl',
      type: 'imgUpload',
      label: 'file.welcome-picture',
      otherOptions: {
        uploadApiAction: 'system/uploadImgFile'
      }
    }
  ],
  validateRules: {
    name: [basicRequiredRule],
    pictureUrl: [basicRequiredRule]
  }
}
